import React, { useState, useEffect, useRef } from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ selectedVideo }) => {
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // 音量変更の間引き処理のためのタイマーID
  const volumeTimeoutRef = useRef(null);
  // プレーヤーの状態変更を追跡するフラグ
  const isPlayerOperationRef = useRef(false);

  useEffect(() => {
    // Check if the device is iOS or mobile
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOSDevice = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    setIsIOS(isIOSDevice);
    setIsMobile(isMobileDevice);

    // ローカルストレージから値を取得
    const storedVolume = localStorage.getItem('videoPlayerVolume');
    if (storedVolume !== null) {
      setVolume(Number(storedVolume));
    }

    const storedMuteState = localStorage.getItem('videoPlayerMuted');
    if (storedMuteState !== null) {
      setIsMuted(storedMuteState === 'true');
    }
  }, []);

  // ボリューム値が変更されたらローカルストレージに保存
  useEffect(() => {
    localStorage.setItem('videoPlayerVolume', volume.toString());
    // 音量変更をYouTubeプレーヤーに適用（遅延処理あり）
    if (player) {
      // タイマーをクリア
      if (volumeTimeoutRef.current) {
        clearTimeout(volumeTimeoutRef.current);
      }

      // 500ms後に音量変更を適用（操作の間引き）
      volumeTimeoutRef.current = setTimeout(() => {
        try {
          isPlayerOperationRef.current = true;
          if (isMuted) {
            player.mute();
          } else {
            player.unMute();
            player.setVolume(volume);
          }
        } catch (error) {
          console.error('Error setting YouTube volume:', error);
        } finally {
          // 操作フラグをリセット
          setTimeout(() => {
            isPlayerOperationRef.current = false;
          }, 200);
        }
      }, 500);
    }
  }, [volume, player, isMuted]);

  // ミュート状態が変更されたらローカルストレージに保存
  useEffect(() => {
    localStorage.setItem('videoPlayerMuted', isMuted.toString());

    // ミュート状態をYouTubeプレーヤーに適用
    if (player) {
      try {
        isPlayerOperationRef.current = true;
        if (isMuted) {
          player.mute();
        } else {
          player.unMute();
          player.setVolume(volume);
        }
      } catch (error) {
        console.error('Error setting YouTube mute state:', error);
      } finally {
        // 操作フラグをリセット
        setTimeout(() => {
          isPlayerOperationRef.current = false;
        }, 200);
      }
    }
  }, [isMuted, player, volume]);

  const onReady = (event) => {
    try {
      const newPlayer = event.target;
      if (newPlayer) {
        setPlayer(newPlayer);

        // 初期音量とミュート状態を設定
        if (isMuted) {
          newPlayer.mute();
        } else {
          newPlayer.unMute();
          newPlayer.setVolume(volume);
        }

        setIsVideoReady(true);
        setIsPlaying(true);

        // iOS対応：自動再生のために一旦ミュートにする必要がある
        if (isIOS) {
          newPlayer.mute();
          setIsMuted(true);
        }
      }
    } catch (error) {
      console.error('Error initializing YouTube player:', error);
    }
  };

  // 動画の再生/停止のみを制御する関数
  const toggleVideo = () => {
    try {
      if (!player) return;

      isPlayerOperationRef.current = true;
      if (isPlaying) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }

      setIsPlaying(!isPlaying);

      // 操作フラグをリセット
      setTimeout(() => {
        isPlayerOperationRef.current = false;
      }, 200);
    } catch (error) {
      console.error('Error toggling video playback:', error);
      isPlayerOperationRef.current = false;
    }
  };

  // 音量コントロール
  const onVolumeChange = (event) => {
    try {
      const newVolume = Number(event.target.value);
      setVolume(newVolume);

      // 0になったらミュート状態にする
      if (newVolume === 0) {
        setIsMuted(true);
      } else if (isMuted) {
        setIsMuted(false);
      }
    } catch (error) {
      console.error('Error updating volume UI:', error);
    }
  };

  // ミュートボタンクリック
  const onClickMute = () => {
    try {
      // iOS/モバイルでもミュート操作は許可
      setIsMuted(!isMuted);
    } catch (error) {
      console.error('Error toggling mute UI:', error);
    }
  };

  // ボリュームアイコンのクラス名を決定
  let volumeClassName = '';
  if (isMuted || volume < 1) {
    volumeClassName = 'icon-volume -mute';
  } else if (volume >= 1 && volume <= 33) {
    volumeClassName = 'icon-volume -low';
  } else if (volume >= 34 && volume <= 66) {
    volumeClassName = 'icon-volume -mid';
  } else if (volume >= 67 && volume <= 100) {
    volumeClassName = 'icon-volume -high';
  }

  const onStateChange = (event) => {
    try {
      // プレーヤー操作によるイベントは無視
      if (isPlayerOperationRef.current) {
        return;
      }

      if (!event || typeof event.data !== 'number') return;

      const playerState = event.data;
      // YT定数の安全なアクセス
      const PLAYING_STATE = window.YT && window.YT.PlayerState ? window.YT.PlayerState.PLAYING : 1;
      const PAUSED_STATE = window.YT && window.YT.PlayerState ? window.YT.PlayerState.PAUSED : 2;
      const BUFFERING_STATE = window.YT && window.YT.PlayerState ? window.YT.PlayerState.BUFFERING : 3;
      const ENDED_STATE = window.YT && window.YT.PlayerState ? window.YT.PlayerState.ENDED : 0;

      if (playerState === PLAYING_STATE) {
        setIsPlaying(true);
      } else if (playerState === PAUSED_STATE || playerState === BUFFERING_STATE || playerState === ENDED_STATE) {
        setIsPlaying(false);
      }
    } catch (error) {
      console.error('Error handling state change:', error);
    }
  };

  return (
    <>
      <div className={`video-container ${isVideoReady ? 'show' : ''}`}>
        <YouTube
          videoId={selectedVideo}
          opts={{
            playerVars: {
              autoplay: 1,
              controls: 0,
              modestbranding: 1,
              rel: 0,
              playsinline: 1, // iOSでインライン再生を許可
            },
          }}
          onReady={onReady}
          onStateChange={onStateChange}
          onError={(e) => console.error('YouTube player error:', e)}
        />
      </div>
      <div className="video-controller">
        <button onClick={toggleVideo} className={isPlaying ? 'button-pause' : 'button-play'}></button>
        <button className={volumeClassName} onClick={onClickMute}></button>
        {!isMobile && (
          <input
            type="range"
            min="0"
            max="100"
            value={volume}
            onChange={onVolumeChange}
          />
        )}
      </div>
    </>
  );
};

export default VideoPlayer;
